import React, { FC } from "react";
import { TextEnum } from "../../shared/constants/textEnum";
import { usePayoutsCardProcessorState } from "./state/usePayoutsCardProcessorState";
import SectionInfoComponent from "../../components/SectionInfoComponent/SectionInfoComponent";
import PaymentProcessorComponent from "../../components/PaymentProcessorComponent/PaymentProcessorComponent";
import ProcessorInfoComponent from "../../components/ProcessorInfoComponent/ProcessorInfoComponent";
import BasicInfoComponent from "../../components/BasicInfoComponent/BasicInfoComponent";
import { ActionButtons } from "../../components/ActionButtons/ActionButtons";
import { Container, Grid, Typography } from "@mui/material";
import { ButtonTextEnum } from "../../shared/constants/processorConstants";
import { payoutsCardProcessorComponentStyles } from "./PayoutsCardProcessorComponent.styles";
import { PROCESSOR_PAYMENT_METHOD } from "../../shared/constants/payoutsCardProcessorOptions";
import { LoadingDialogComponent } from "../../components/Dialog/LoadingDialogComponent";
import { ProcessorDialogComponent } from "../../components/Dialog/ProcessorDialogComponent";
import { FormProvider } from "react-hook-form";
import { SnackbarAlert } from "../../components/SnackBarAlert/SnackBarAlert";

const PayoutsCardProcessorComponent: FC = () => {
  const {
    processorTypes,
    setProcessorTypes,
    modelOptions,
    setModelOptions,
    categoryOptions,
    setCategoryOptions,
    handleSave,
    merchantInfo,
    loadingModal,
    showCreatingProcessorModal,
    registeredProcessorAlias,
    merchantId,
    handleCloseFinalModal,
    showFinalModal,
    defaultProcessor,
    isEdit,
    isDefaultProcessor,
    form,
    handleChangeShowSnackbar,
    handleOnReturn,
    disableCheckbox,
    snackbarError,
    getMerchantInfo,
  } = usePayoutsCardProcessorState();
  const saveTextButton: string = isEdit
    ? ButtonTextEnum.UPDATE_BUTTON
    : ButtonTextEnum.SAVE_BUTTON;

  return (
    <React.Fragment>
      <Container>
        <SnackbarAlert
          msg={snackbarError.errorMessage}
          type={"error"}
          show={snackbarError.showSnackbar}
          handleChangeShowSnackbar={handleChangeShowSnackbar}
        />
        <FormProvider {...form}>
          <Grid>
            <Typography sx={payoutsCardProcessorComponentStyles.title}>
              {isEdit
                ? TextEnum.editPaymentProcessorTitle
                : TextEnum.addPaymentProcessorTitle}
            </Typography>
          </Grid>
          <SectionInfoComponent
            title={TextEnum.paymentProcessorTitle}
            description={TextEnum.paymentProcessorDescription}
          >
            <PaymentProcessorComponent
              chipTitle={TextEnum.paymentProcessorChipHeader}
              control={form.control}
              isLoading={loadingModal.isLoading}
              processorsTypes={PROCESSOR_PAYMENT_METHOD}
              processorsOptions={{
                info: {
                  items: processorTypes,
                  name: "ProcessorTypeRadioButton",
                  updateList: setProcessorTypes,
                },
              }}
            />
          </SectionInfoComponent>
          <SectionInfoComponent
            title={TextEnum.basicInfoTitle}
            description={TextEnum.basicInfoDescription}
          >
            <BasicInfoComponent
              control={form.control}
              modelTitle={TextEnum.modelTitle}
              categoryTitle={TextEnum.categoryTitle}
              merchantType={TextEnum.merchantType}
              modelOptions={{
                info: {
                  items: modelOptions,
                  name: "ModelTypeRadioButton",
                  updateList: setModelOptions,
                },
              }}
              categoryOptions={{
                info: {
                  items: categoryOptions,
                  name: "CategoryRadioButton",
                  updateList: setCategoryOptions,
                },
              }}
              isLoading={loadingModal.isLoading}
            />
          </SectionInfoComponent>
          <SectionInfoComponent
            title={TextEnum.processorInfoTitle}
            description={TextEnum.processorInfoDesc}
          >
            <ProcessorInfoComponent
              control={form.control}
              isEdit={isEdit}
              isLoading={loadingModal.isLoading}
              title={TextEnum.processorInfoHeader}
              merchantInfo={merchantInfo}
              name={registeredProcessorAlias}
              errors={form.errors}
              defaultProcessor={defaultProcessor}
              isDefaultProcessor={isDefaultProcessor}
              disableCheckbox={disableCheckbox}
            />
          </SectionInfoComponent>
          <Grid sx={payoutsCardProcessorComponentStyles.actionButtons}>
            <ActionButtons
              disabled={false}
              saveButtonText={saveTextButton}
              handleSave={form.handleSubmit(handleSave)}
              onReturn={handleOnReturn}
            />
          </Grid>
          <LoadingDialogComponent
            open={showCreatingProcessorModal}
            isEdit={isEdit}
          />
          <ProcessorDialogComponent
            open={showFinalModal}
            redirectObject={getMerchantInfo(merchantId)}
            onClose={handleCloseFinalModal}
          />
        </FormProvider>
      </Container>
    </React.Fragment>
  );
};

export default PayoutsCardProcessorComponent;
