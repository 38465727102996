import React from "react";
import { Copy } from "react-feather";
import { Grid, IconButton, TextField, Tooltip } from "@mui/material";
import { LabelField, TextEnum } from "../../../shared/constants/textEnum";
import { clipboard } from "../../../shared/utils/clipboard";
import { Control, Controller } from "react-hook-form";
import { CreateProcessor } from "../../../../types/create_processor";
import { InputNameEnum } from "../../../shared/constants/processorConstants";
import { processorInfoComponentStyles } from "../ProcessorInfoComponent.styles";
import { MaskSkeleton } from "../../MaskSkeleton/MaskSkeleton";
import { commerceCodeStyles } from "./CommerceCode.styles";

export interface CommerceCodeProps {
  control: Control<CreateProcessor>;
  isLoading: boolean;
}

export const CommerceCode: React.FC<CommerceCodeProps> = (
  props: CommerceCodeProps
) => {
  const classes = commerceCodeStyles();
  const [open, setOpen] = React.useState<boolean>(false);
  const handleTooltip = () => {
    setOpen(!open);
  };

  return (
    <Grid item xs={12} sx={processorInfoComponentStyles.formContainer}>
      <Grid item xs={12}>
        <MaskSkeleton
          sx={processorInfoComponentStyles.fieldSkeleton}
          isLoading={props.isLoading}
        >
          <Controller
            name={InputNameEnum.COMMERCE_CODE}
            control={props.control}
            render={({ onChange, value }) => (
              <TextField
                disabled
                fullWidth
                onChange={onChange}
                variant="outlined"
                value={value || ""}
                id={InputNameEnum.COMMERCE_CODE}
                label={LabelField.UniqueCode}
                className={classes.textField}
                InputProps={{
                  endAdornment: (
                    <Tooltip
                      id={"copyTooltip"}
                      title={TextEnum.copyTooltip}
                      defaultValue={value}
                      leaveDelay={1000}
                      open={open}
                      onClose={handleTooltip}
                      placement={"top"}
                    >
                      <IconButton
                        id={"copyButton"}
                        aria-label="copy"
                        onClick={() => {
                          clipboard(value || "");
                          handleTooltip();
                        }}
                      >
                        <Copy size={20} />
                      </IconButton>
                    </Tooltip>
                  ),
                }}
              />
            )}
          />
        </MaskSkeleton>
      </Grid>
    </Grid>
  );
};
