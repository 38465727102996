import React, { FC } from "react";
import {
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { IProcessorInfoComponent } from "./ProcessorInfoComponent.interfaces";
import { defaultTo, get } from "lodash";
import { processorInfoComponentStyles } from "./ProcessorInfoComponent.styles";
import { MaskSkeleton } from "../MaskSkeleton/MaskSkeleton";
import { MessageBox } from "../MessageBox/MessageBox";
import { TextEnum } from "../../shared/constants/textEnum";
import { Controller } from "react-hook-form";
import { useProcessorInfoState } from "./useState/useProcessorInfoState";
import { InputNameEnum } from "../../shared/constants/processorConstants";
import { ErrorsEnum } from "../../shared/constants/ErrorMessage";
import { REG_EXP_LITERALS } from "../../shared/constants/RegexValidations";
import { CommerceCode } from "./CommerceCode/CommerceCode";

const ProcessorInfoComponent: FC<IProcessorInfoComponent> = (
  props: IProcessorInfoComponent
) => {
  const { getCurrencyByCountry } = useProcessorInfoState();

  return (
    <React.Fragment>
      <Card>
        <CardContent sx={processorInfoComponentStyles.cardContent}>
          <Grid container>
            <Grid item xs={12} sx={processorInfoComponentStyles.basicContainer}>
              <Grid item xs={12} sx={processorInfoComponentStyles.titleBasic}>
                <Typography sx={processorInfoComponentStyles.title}>
                  {get(props, "title", "")}
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sx={processorInfoComponentStyles.formContainer}
              >
                <Grid item xs={12}>
                  <MaskSkeleton
                    sx={processorInfoComponentStyles.fieldSkeleton}
                    isLoading={props.isLoading}
                  >
                    <Controller
                      name={InputNameEnum.PROCESSOR_ALIAS}
                      control={props.control}
                      rules={{
                        required: TextEnum.requiredDataMessage,
                        validate: (value) =>
                          !props.name.includes(value) ||
                          TextEnum.existingAliasMessage,
                      }}
                      render={({ onChange, value }) => (
                        <TextField
                          id={InputNameEnum.PROCESSOR_ALIAS}
                          label="Alias"
                          variant="outlined"
                          fullWidth
                          value={value}
                          onChange={onChange}
                          error={!!props.errors.processorAlias}
                          helperText={
                            props.errors.processorAlias &&
                            `${props.errors.processorAlias.message}`
                          }
                        />
                      )}
                    />
                  </MaskSkeleton>
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                sx={processorInfoComponentStyles.formContainer}
              >
                <Grid
                  item
                  xs={6}
                  sx={processorInfoComponentStyles.currencyContainer}
                >
                  <MaskSkeleton
                    sx={processorInfoComponentStyles.fieldSkeleton}
                    isLoading={props.isLoading}
                  >
                    <Controller
                      name={InputNameEnum.CURRENCY}
                      control={props.control}
                      defaultValue={""}
                      rules={{
                        required: TextEnum.requiredDataMessage,
                        validate: (value) =>
                          value !== "" || TextEnum.requiredDataMessage,
                      }}
                      render={({ onChange, value }) => (
                        <TextField
                          fullWidth
                          label="Moneda"
                          onChange={onChange}
                          value={value}
                          select
                          error={!!props.errors.currency}
                          helperText={
                            props.errors.currency
                              ? props.errors.currency.message
                              : ""
                          }
                        >
                          {getCurrencyByCountry(
                            defaultTo(
                              props.merchantInfo.country,
                              ""
                            ).toUpperCase()
                          ).map((currency: string) => (
                            <MenuItem key={currency} value={currency}>
                              {currency}
                            </MenuItem>
                          ))}
                        </TextField>
                      )}
                    />
                  </MaskSkeleton>
                </Grid>
                <Grid item xs={6} sx={{ marginLeft: "5px" }}>
                  <MaskSkeleton
                    sx={processorInfoComponentStyles.fieldSkeleton}
                    isLoading={props.isLoading}
                  >
                    <Controller
                      rules={{
                        maxLength: {
                          message: ErrorsEnum.MCC_CODE_NUMBERS_ONLY,
                          value: 4,
                        },
                        minLength: {
                          message: ErrorsEnum.MCC_CODE_NUMBERS_ONLY,
                          value: 4,
                        },
                        pattern: {
                          message: ErrorsEnum.INVALID_VALUE,
                          value: REG_EXP_LITERALS.onlyValidMCC,
                        },
                        required: TextEnum.requiredDataMessage,
                      }}
                      name={InputNameEnum.MCC}
                      control={props.control}
                      render={({ onChange, value }) => (
                        <TextField
                          id={InputNameEnum.MCC}
                          label="MCC"
                          variant="outlined"
                          fullWidth
                          value={value || ""}
                          onChange={onChange}
                          error={get(props, "errors.mcc", "") || ""}
                          helperText={
                            get(props, "errors.mcc.message", "") || ""
                          }
                        />
                      )}
                    />
                  </MaskSkeleton>
                </Grid>
              </Grid>
              {props.isEdit && (
                <CommerceCode
                  control={props.control}
                  isLoading={props.isLoading}
                />
              )}
              {!props.isDefaultProcessor &&
                get(props, "defaultProcessor.publicProcessorId", "") != "" && (
                  <MessageBox
                    title={TextEnum.messageBoxTitle}
                    description={TextEnum.messageBoxDescription.replace(
                      "{p}",
                      props.defaultProcessor.processorAlias
                    )}
                  />
                )}

              {!props.isEdit && (
                <MaskSkeleton
                  sx={processorInfoComponentStyles.fieldSkeleton}
                  isLoading={props.isLoading}
                >
                  <Controller
                    name="defaultProcessor"
                    control={props.control}
                    render={({ onChange, value }) => (
                      <FormControlLabel
                        key={"enable_as_default_processor"}
                        control={
                          <Checkbox
                            checked={value}
                            disabled={props.disableCheckbox}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => onChange(e.target.checked)}
                          />
                        }
                        sx={processorInfoComponentStyles.checkboxCheckButton}
                        label={
                          <Typography
                            sx={processorInfoComponentStyles.checkboxLabel}
                          >
                            {TextEnum.setAsDefaultProcessor}
                          </Typography>
                        }
                        labelPlacement="end"
                      />
                    )}
                  />
                </MaskSkeleton>
              )}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </React.Fragment>
  );
};

export default ProcessorInfoComponent;
