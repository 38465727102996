export enum SecurityRulesRoles {
  MODULE_ID = "M_MERCHANTS",
  PROCESSOR_ADD_PROCESSOR = "M_MERCHANTS.Procesadores.PayoutCard.AgregarProcesador",
}

export enum ButtonTextEnum {
  SAVE_BUTTON = "Guardar",
  RETURN_BUTTON = "Regresar",
  UPDATE_BUTTON = "Actualizar",
}

export enum CountryEnum {
  PERU = "PERU",
  CHILE = "CHILE",
  COLOMBIA = "COLOMBIA",
  MEXICO = "MEXICO",
}

export enum CurrencyEnum {
  PEN = "PEN",
  CLP = "CLP",
  COP = "COP",
  MXN = "MXN",
  USD = "USD",
  UF = "UF",
}

export enum InputNameEnum {
  COMMERCE_CODE = "commerceCode",
  MERCHANT_MODEL = "merchantModel",
  MERCHANT_CATEGORY = "merchantCategory",
  PROCESSOR_NAME = "processorName",
  MCC = "mcc",
  CURRENCY = "currency",
  PROCESSOR_ALIAS = "processorAlias",
  PCI_MERCHANT = "isPciMerchant",
}

export interface BreadcrumbItem {
  label: string;
  url: string;
}

export enum OriginEnum {
  consoleMerchant = "consoleMerchant",
  createMerchantV3 = "createMerchantV3",
}

export interface RedirectObject {
  redirectPath: string;
  redirectButtonLabel: string;
  redirectAddProcessorLabel?: string;
  redirectPathAddProcessor?: string;
  redirectCancelLabel?: string;
}

export enum StatusEnum {
  ENABLED = "ENABLED",
}
