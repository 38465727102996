import React, { FC } from "react";
import {
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
} from "@mui/material";
import { MaskSkeleton } from "../MaskSkeleton/MaskSkeleton";
import RadioGroupComponent from "../RadioGroupComponent/RadioGroupComponent";
import { IBasicInfoProps } from "./BasicInfoComponent.interfaces";
import { basicInfoStyles } from "./BasicInfoComponent.styles";
import { InputNameEnum } from "../../shared/constants/processorConstants";
import { TextEnum } from "../../shared/constants/textEnum";
import { MessageBox } from "../MessageBox/MessageBox";
import { processorInfoComponentStyles } from "../ProcessorInfoComponent/ProcessorInfoComponent.styles";
import { Controller } from "react-hook-form";

const BasicInfoComponent: FC<IBasicInfoProps> = (props: IBasicInfoProps) => {
  return (
    <React.Fragment>
      <Card>
        <CardContent sx={basicInfoStyles.cardContent}>
          <Grid container sx={basicInfoStyles.subsection}>
            <Grid item xs={12}>
              <Grid item sx={basicInfoStyles.titleChip}>
                <Typography
                  key={"chiptitle_agregator_model"}
                  sx={basicInfoStyles.title}
                >
                  {props.modelTitle}
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <MaskSkeleton
                sx={basicInfoStyles.radioSkeleton}
                isLoading={props.isLoading}
              >
                <RadioGroupComponent
                  updateList={props.modelOptions.info.updateList}
                  name={props.modelOptions.info.name}
                  items={props.modelOptions.info.items}
                  control={props.control}
                  groupName={InputNameEnum.MERCHANT_MODEL}
                />
              </MaskSkeleton>
            </Grid>
          </Grid>
          <Grid container sx={basicInfoStyles.subsection}>
            <Grid item xs={12}>
              <Grid item sx={basicInfoStyles.titleChip}>
                <Typography
                  key={"chiptitle_formal_category"}
                  sx={basicInfoStyles.title}
                >
                  {props.categoryTitle}
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <MaskSkeleton
                sx={basicInfoStyles.radioSkeleton}
                isLoading={props.isLoading}
              >
                <RadioGroupComponent
                  updateList={props.categoryOptions.info.updateList}
                  name={props.categoryOptions.info.name}
                  items={props.categoryOptions.info.items}
                  control={props.control}
                  groupName={InputNameEnum.MERCHANT_CATEGORY}
                />
              </MaskSkeleton>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12}>
              <Grid item sx={basicInfoStyles.titleChip}>
                <Typography
                  key={"typography_merchant_type"}
                  sx={basicInfoStyles.title}
                >
                  {props.merchantType}
                </Typography>
              </Grid>
            </Grid>
            {!props.isLoading && (
              <Grid item sx={basicInfoStyles.pciAlertMessage}>
                <MessageBox
                  title={"Información"}
                  description={
                    "La siguiente opción está relacionada con el procesamiento de tus datos. Si el comercio no es PCI, nosotros nos encargamos de gestionar y manejar tu información de forma segura."
                  }
                />
              </Grid>
            )}
            <Grid item sx={basicInfoStyles.titleChip}>
              <MaskSkeleton
                sx={processorInfoComponentStyles.fieldSkeleton}
                isLoading={props.isLoading}
              >
                <Controller
                  name="isPciMerchant"
                  control={props.control}
                  render={({ onChange, value }) => (
                    <FormControlLabel
                      key={"pci_processor"}
                      control={
                        <Checkbox
                          checked={value}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            onChange(e.target.checked)
                          }
                        />
                      }
                      sx={processorInfoComponentStyles.checkboxCheckButton}
                      label={
                        <Typography
                          sx={processorInfoComponentStyles.checkboxLabel}
                        >
                          {TextEnum.PCIProcessor}
                        </Typography>
                      }
                      labelPlacement="end"
                    />
                  )}
                />
              </MaskSkeleton>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </React.Fragment>
  );
};

export default BasicInfoComponent;
